(function ($) {
    $('.main-header').each(function() {
        var lastScrollTop = 0;

        $(window).scroll(function(e) {
            if ($(window).scrollTop() > 60) {
                $('.main-header').addClass('scrolled');
            } else {
                $('.main-header').removeClass('scrolled');
            }

            var st = $(this).scrollTop();
            if (st > lastScrollTop){
                $('.main-header').removeClass('scrollUp').addClass('scrollDown');
            } else {
                $('.main-header').removeClass('scrollDown').addClass('scrollUp');
            }
            lastScrollTop = st;
        });
    });
})(jQuery);
