(function ($) {
  $('.news-carousel .swiper-container').each(function() {
    new Swiper('.news-carousel .swiper-container', {
      loop: true,
      pagination: {
        el: '.news-carousel .swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      autoplay: {
        delay: 5000,
      },
    });
  });
})(jQuery);