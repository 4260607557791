(function ($) {
   $('#nav').each(function() {
      $(document).on('click', ".toggle-menu", function() {
        $(this).toggleClass('open');
        $('body').toggleClass('menu-open');
      });

      $(".menu-wrapper").each(function() {
        var el = $(this).find("a, span");
        var elActive = $(this).find("a.is-active");

        elActive.parent("li").addClass("active");
        elActive.parent("li").parents("li").addClass("active-trail");

        /*if($(this).children("ul").find("ul").length) {
          $(this).children("ul").addClass("sublvl");
        }*/

        /*el.each(function() {
          if($(this).next("ul").length) {
            $(this).after('<span class="expand"></span>');
          }
        });*/
      });

      /*$(document).on("click", ".menu-wrapper .expand", function() {
        $(this).parent("li").toggleClass('open');
      });*/
   });
})(jQuery);

